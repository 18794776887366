import React, { useState } from "react";
import Layout from "../components/Layout";
import Input from "../components/common/Input";
import axios from "axios";

const CrossIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    fill="currentColor"
    className="cross-icon"
  >
    <path d="M18.3 5.71a1 1 0 0 0-1.42 0L12 10.59 7.12 5.71A1 1 0 0 0 5.7 7.12L10.59 12 5.7 16.88a1 1 0 1 0 1.42 1.42L12 13.41l4.88 4.88a1 1 0 0 0 1.42-1.42L13.41 12l4.88-4.88a1 1 0 0 0 0-1.41z" />
  </svg>
);

const ReferralBanner = ({ onButtonClick }) => {
  return (
    <div className="banner referral-banner">
      <div className="content">
        <div className="banner-wr">
          <div className="banner-txt text-white referral-c">
            <h1>AuditCover Referral Marketing Program</h1>
            <p className="referral-sub-title">
              Refer Fellow Accountants and Earn Rewards
            </p>
            <div className="referral-desc">
              Join our Referral Marketing Program and help us grow our network
              of trusted accountants. By referring fellow accountants to
              AuditCover, you can earn exclusive rewards and gain recognition
              for your firm.
            </div>
          </div>

          <div className="referral-banner-btn">
            <button
              type="button"
              className="cta cta-top long w-button cta-top-mirror"
              onClick={onButtonClick}
            >
              Refer an accountant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const data = [
  {
    id: "1",
    title: "Feature Your Firm",
    desc: "Refer an accountant, and we’ll feature your firm’s logo in our monthly newsletter, putting a spotlight on your practice to our 6000 recipient database.",
  },
  {
    id: "2",
    title: "Earn a $200 Visa Card",
    desc: "For every accountant you refer, you’ll receive a $200 Visa card as a thank you from our team once they reach 20 policyholders.",
  },
  {
    id: "3",
    title: "Ready to get started?",
    desc: "Fill out the form below to refer your colleagues and start earning rewards!",
  },
];

const ReferralCTA = () => {
  return (
    <div className="nu-icons-container">
      <div className="content nu-c">
        <div className="nu-icons">
          {data.map(({ id, src, title, desc }) => (
            <div key={id} className="nu-icon--item referral-cta-item">
              <div className="referral-cta-number">{id}</div>
              <span>{title}</span>
              <div>{desc}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validatePhone = (phone) => {
  const phoneRegex = /^(?:\+?61|0)4\d{8}$/;
  return phoneRegex.test(phone);
};

const validateField = (field, value) => {
  if (field === "email" && !validateEmail(value)) {
    return "Email is invalid";
  }

  if (!value) {
    return `This is a required field.`;
  }
  return "";
};

const ReferralContact = () => {
  const [referrerData, setReferrerData] = useState({});
  const [referrerErrors, setReferrerErrors] = useState({});
  const [referrals, setReferrals] = useState([{ id: 1, data: {}, errors: {} }]);
  const [isDisableSubmit, setIsDisableSubmit] = useState(false);
  const [isDisplayForm, setIsDisplayForm] = useState(true);

  const handleReferrerInputChange = (field, value) => {
    setReferrerData((prev) => ({ ...prev, [field]: value }));
    setReferrerErrors((prev) => ({
      ...prev,
      [field]: validateField(field, value),
    }));
  };

  const addReferral = () => {
    if (referrals.length < 5) {
      setReferrals([
        ...referrals,
        { id: referrals.length + 1, data: {}, errors: {} },
      ]);
    }
  };

  const removeReferral = (id) => {
    if (referrals.length > 1) {
      setReferrals(referrals.filter((referral) => referral.id !== id));
    }
  };

  const handleInputChange = (id, field, value) => {
    const newReferrals = referrals.map((referral) => {
      if (referral.id === id) {
        referral.data[field] = value;
        referral.errors[field] = validateField(field, value);
      }
      return referral;
    });
    setReferrals(newReferrals);
    setIsDisableSubmit(false);
  };

  const validateAllFields = () => {
    const requiredFields = ["fullName", "email", "firm"];

    const newReferrerErrors = {};
    requiredFields.forEach((key) => {
      const error = validateField(key, referrerData[key]);
      if (error) {
        newReferrerErrors[key] = error;
      }
    });
    setReferrerErrors(newReferrerErrors);

    const newReferrals = referrals.map((referral) => {
      const newErrors = {};
      requiredFields.forEach((key) => {
        const error = validateField(key, referral.data[key]);
        if (error) {
          newErrors[key] = error;
        }
      });
      return { ...referral, errors: newErrors };
    });
    setReferrals(newReferrals);

    const isReferrerValid = Object.keys(newReferrerErrors).length === 0;
    const areReferralsValid = newReferrals.every(
      (referral) => Object.keys(referral.errors).length === 0
    );

    return isReferrerValid && areReferralsValid;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsDisableSubmit(true);

    const isInputValid = validateAllFields();

    if (!isInputValid) {
      return;
    }

    // Send Slack notification
    const webhookUrl =
      "https://hooks.slack.com/services/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";
    const message =
      `--------------------------------------\n` +
      `*Referrer Information*\n` +
      `*Full Name:* ${referrerData.fullName || ""}\n` +
      `*Email:* ${referrerData.email || ""}\n` +
      `*Phone:* ${referrerData.phone || ""}\n` +
      `*Firm:* ${referrerData.firm || ""}\n` +
      `*Comments:* ${referrerData.comments || ""}\n\n` +
      `*Referral Information*\n` +
      referrals
        .map(
          (referral, index) =>
            `Referral ${index + 1}:\n` +
            `*Full Name:* ${referral.data.fullName || ""}\n` +
            `*Email:* ${referral.data.email || ""}\n` +
            `*Phone:* ${referral.data.phone || ""}\n` +
            `*Firm:* ${referral.data.firm || ""}\n` +
            `*Comments:* ${referral.data.comments || ""}\n`
        )
        .join("\n") +
      `--------------------------------------`;

    const data = { text: message };

    try {
      let slackRes = await axios.post(webhookUrl, data, {
        withCredentials: false,
        transformRequest: [
          (data) => {
            return JSON.stringify(data);
          },
        ],
      });

      if (slackRes.status === 200) {
        setIsDisplayForm(false);
      }
    } catch (error) {
      console.error("Error sending Slack notification:", error);
    } finally {
      setIsDisableSubmit(false);
    }
  };

  return (
    <div id="referral-contact" className="referral-contact-container">
      {isDisplayForm ? (
        <>
          <div className="referral-contact-title">Submit Your Referral</div>

          <div className="content">
            <form onSubmit={handleFormSubmit}>
              <div className="switch-contact-grid">
                <div className="referral-contact-left">
                  <h2 className="referral-contact-left-title">Your Details</h2>
                  <Input
                    type="text"
                    name="referrerFullName"
                    className="switch-contact-input"
                    placeholder="Full name"
                    error={referrerErrors.fullName}
                    onChange={(e) =>
                      handleReferrerInputChange("fullName", e.target.value)
                    }
                  />
                  <Input
                    type="email"
                    name="referrerEmail"
                    className="switch-contact-input"
                    placeholder="Email Address"
                    error={referrerErrors.email}
                    onChange={(e) =>
                      handleReferrerInputChange("email", e.target.value)
                    }
                  />
                  <Input
                    type="tel"
                    name="referrerPhone"
                    className="switch-contact-input"
                    placeholder="Phone Number"
                    error={referrerErrors.phone}
                    onChange={(e) =>
                      handleReferrerInputChange("phone", e.target.value)
                    }
                  />
                  <Input
                    type="text"
                    name="referrerFirm"
                    className="switch-contact-input"
                    placeholder="Firm Name"
                    error={referrerErrors.firm}
                    onChange={(e) =>
                      handleReferrerInputChange("firm", e.target.value)
                    }
                  />
                  <Input
                    type="text"
                    name="referrerComments"
                    className="switch-contact-input"
                    placeholder="Additional Comments (optional)"
                    onChange={(e) =>
                      handleReferrerInputChange("comments", e.target.value)
                    }
                  />
                </div>

                <div className="referral-contact-right">
                  <h2 className="referral-contact-right-title">
                    Referral Information
                  </h2>
                  {referrals.map((referral, index) => (
                    <div key={referral.id} className="referral-entry">
                      <div className="referral-sub-number-wrapper">
                        <div className="text-white referral-sub-number">{`Referral ${
                          index + 1
                        }`}</div>
                        {index !== 0 && (
                          <div
                            className="referral-contact-removal"
                            onClick={() => removeReferral(referral.id)}
                          >
                            <CrossIcon />
                          </div>
                        )}
                      </div>

                      <Input
                        type="text"
                        name={`referralFullName${referral.id}`}
                        className="switch-contact-input"
                        placeholder="Full name"
                        error={referral.errors.fullName}
                        onChange={(e) =>
                          handleInputChange(
                            referral.id,
                            "fullName",
                            e.target.value
                          )
                        }
                      />
                      <Input
                        type="email"
                        name={`referralEmail${referral.id}`}
                        className="switch-contact-input"
                        placeholder="Email Address"
                        error={referral.errors.email}
                        onChange={(e) =>
                          handleInputChange(
                            referral.id,
                            "email",
                            e.target.value
                          )
                        }
                      />
                      <Input
                        type="tel"
                        name={`referralPhone${referral.id}`}
                        className="switch-contact-input"
                        placeholder="Phone Number"
                        error={referral.errors.phone}
                        onChange={(e) =>
                          handleInputChange(
                            referral.id,
                            "phone",
                            e.target.value
                          )
                        }
                      />
                      <Input
                        type="text"
                        name={`referralFirm${referral.id}`}
                        className="switch-contact-input"
                        placeholder="Firm Name"
                        error={referral.errors.firm}
                        onChange={(e) =>
                          handleInputChange(referral.id, "firm", e.target.value)
                        }
                      />
                      <Input
                        type="text"
                        name={`referralComments${referral.id}`}
                        className="switch-contact-input"
                        placeholder="Additional Comments (optional)"
                        onChange={(e) =>
                          handleInputChange(
                            referral.id,
                            "comments",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  ))}

                  <div className="referral-contact-btn-group">
                    <button
                      type="button"
                      className="referral-contact-submit-btn"
                      onClick={addReferral}
                    >
                      Add Referral
                    </button>

                    <button
                      type="submit"
                      className="referral-contact-submit-btn"
                      disabled={isDisableSubmit}
                    >
                      Submit Referral
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className="form-success-msg text-white">
          Thanks for the request! We will get in touch with you shortly.
        </div>
      )}
    </div>
  );
};

const ReferralProgramPage = () => {
  const scrollToContact = () => {
    const element = document.getElementById("referral-contact");
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    }
  };
  return (
    <Layout
      bg="home-bg"
      banner={<ReferralBanner onButtonClick={scrollToContact} />}
      isDisableBottomCta={false}
      isDisplayBottomPartner={false}
      isDisplayGetQuoteBtn={false}
      hideBookButton={true}
      isRoundedNone={true}
      isDisableSecondColumn={true}
    >
      <ReferralCTA />
      <ReferralContact />
    </Layout>
  );
};

export default ReferralProgramPage;
